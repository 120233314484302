import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UplaodCloud from '../../assets/icons/upload-cloud.svg';
import CustomButton from '../../components/form/CustomButton';
import PrimaryButton from '../../components/form/PrimaryButton';
import { uploadResume } from '../../services/apiServices'; // Assuming you have an uploadResume API service
import pdfToText from "react-pdftotext"; // Ensure you import this at the top

const UploadResume = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate

  // Extract mobileNumber from location.state
  const mobileNumber = location.state?.mobileNumber || ""; // Fallback to empty string if not available

  // Handle file selection via input or drag-and-drop
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    validateFile(file);
  };

  // Validate the file type
  const validateFile = (file) => {
    const allowedFormats = [".pdf", ".docx", ".doc", ".txt"];
    const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    if (allowedFormats.includes(`.${fileExtension}`)) {
      setSelectedFile(file);
      setErrorMessage('');
      setUploadMessage(file.name);
    } else {
      setErrorMessage('Invalid file format. Please upload a .pdf, .doc, .docx, or .txt file.');
      setUploadMessage('Incorrect File Format');
    }
  };

  // Handle file drag-over
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  // Handle file drop
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    validateFile(file);
  };

  // Function to handle the file upload and extract relevant data
  const handleFileUpload = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select a file to upload.');
      return;
    }

    try {
      // Extract text from the PDF file
      const text = await pdfToText(selectedFile);
      console.log("Extracted Text:", text); // Log the extracted text for debugging

      // Use regular expressions or string manipulation to extract data
      const emailMatch = text.match(/([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/);
      const dobMatch = text.match(/Date of Birth:\s*(\d{1,2}[/-]\d{1,2}[/-]\d{2,4})/);
      const nameMatch = text.match(/Name:\s*(.*)/);
      const experienceMatch = text.match(/Experience:\s*(.*)/);

      const email = emailMatch ? emailMatch[0] : '';
      const dob = dobMatch ? dobMatch[1] : '';
      const name = nameMatch ? nameMatch[1] : '';
      const experience = experienceMatch ? experienceMatch[1] : '';

      // Log the extracted data
      console.log("Extracted Data:", { email, dob, name, experience });

      // Assuming setUserData is defined elsewhere in your component hierarchy
      // For example, you could use context or props to set this data
      // setUserData({ email, mobile: mobileNumber, dob, name, experience });

      // Navigate to the create profile page
      navigate("/create-profile");
      
    } catch (error) {
      console.error("Error extracting text from PDF:", error);
      setErrorMessage('An error occurred while extracting data from the CV. Please try again.');
    }
  };

  // Handle navigation when "Skip" button is clicked
  const handleSkip = () => {
    navigate('/create-profile'); // Navigate to create-profile page
  };

  return (
    <>
      <section className='content'>
        <div className='container'>
          <div className='upload_container container_box card'>
            <h2>Upload Your Resume</h2>
            <p>Save time with super-fast Profile Auto-Fill feature when you Upload your Resume</p>

            {/* Drag and Drop Area */}
            <div
              className={`uploadbox ${isDragging ? 'dragging' : ''}`}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onDragLeave={() => setIsDragging(false)}
            >
              <div className='card shadow p-4'>
                <div className='uploadbox_inr'>
                  <img src={UplaodCloud} alt='Upload Cloud' />
                  <p><strong>Supported File Formats</strong></p>
                  <p>(.pdf, .docx, .doc, .txt)</p>
                  {uploadMessage && <p>Selected File: {uploadMessage}</p>}
                  <input 
                    type="file" 
                    className="upload_file" 
                    onChange={handleFileChange} 
                    accept=".pdf, .doc, .docx, .txt" 
                  />
                </div>
                <div className='upload_button mt-3'>
                  <PrimaryButton 
                    text='Upload Resume' 
                    onClick={handleFileUpload} 
                    className='btn btn-sm' 
                  />
                </div>
              </div>
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              {uploadSuccess && <p style={{ color: 'green' }}>File uploaded successfully!</p>}
            </div>

            {/* Skip Button */}
            <CustomButton 
              text='Skip & Add Details Manually' 
              btnclass='btn-white' 
              onClick={handleSkip} 
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default UploadResume;
