import React, { useState, useEffect } from 'react';
import icons from "../../assets/icons";
import UpArrowIcon from '../../assets/icons/Vectordown.svg';
import { usePlacesWidget } from "react-google-autocomplete";
import Autocomplete from "../../components/form/Autocomplete";
import DynamicDropdown from '../../components/form/DynamicDropdown';
import CustomButton from '../../components/form/CustomButton';
import InputWithIcon from '../../components/form/InputWithIcon';
import { useNavigate } from 'react-router-dom';

const SearchJobs = () => {
    const navigate = useNavigate();
    const userId = localStorage.getItem("login_token");

    const [jobRole, setJobRole] = useState('');
    const [experience, setExperience] = useState('');
    const [location, setLocation] = useState('');
    const [workMode, setWorkMode] = useState('');
    const [salaryRange, setSalaryRange] = useState('');
    const [postedBy, setPostedBy] = useState('');
    const [companyType, setCompanyType] = useState('');
    const [freshness, setFreshness] = useState('');
    const [advanceSearch, setAdvanceSearch] = useState(false);
    const [deiCategory, setdeiCategory] = useState('');
    const [gender, setGender] = useState('');

    // Log selected values
    useEffect(() => {
        console.log("Work Mode selected:", workMode);
    }, [workMode]);

    useEffect(() => {
        console.log("Salary Range selected:", salaryRange);
    }, [salaryRange]);

    useEffect(() => {
        console.log("Company Type selected:", companyType);
    }, [companyType]);

    useEffect(() => {
      console.log("Posted By selected:", postedBy);
  }, [postedBy]);

    const { ref } = usePlacesWidget({
      apiKey: 'AIzaSyBo6F5VIs1WvisRrmlwOUXq1T_IEITYTkw',
      onPlaceSelected: (place) => console.log(place)
    });

    // Handle selection from the Autocomplete component
    const handleSelect = (suggestion) => {
        console.log('Selected suggestion:', suggestion);
        setJobRole(suggestion.label); // Set the selected job role
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://deijobs.in/deijobs-api/api/get-filtered-job-list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    jobRole:jobRole,
                    experience:experience,
                    search_location: location,
                    workMode,
                    salaryRange,
                    postedBy,
                    companyType,
                    freshness,
                    deiCategory,
                    gender,
                }),
            });

            const data = await response.json();
            navigate('/search-results', { state: { results: data.data || [] } });
        } catch (error) {
            console.error('Error fetching job results:', error);
        }
    };

    return (
        <section className='content'>
            <div className='container'>
                {/* Autocomplete for Job Role */}
                <Autocomplete
                    icon={<img src={icons["search.svg"]} alt="Search Icon" />}
                    className='no-border'
                    endpoint='get-skill-list'
                    onSelect={handleSelect}  // When a job role is selected, update state
                    placeholder="Search by Title, skill or category"
                />

                {/* Input for Location with Google Places */}
                <InputWithIcon icon={<img src={icons['location_job.svg']} alt="Location Icon" />}>
                    <input
                        type="text"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)} 
                        className='form-control'
                        placeholder='Location'
                        ref={ref}
                    />
                </InputWithIcon>

                {/* Advanced Search Toggle */}
                <div className='advanced-search-toggle'>
                    <a
                        href="#"
                        className="d-flex py-4"
                        onClick={() => setAdvanceSearch(!advanceSearch)}
                        style={{ alignItems: 'center', justifyContent: 'space-between',display:'block'}}
                    >
                        Advance Search
                        {advanceSearch ? (
                            <img src={UpArrowIcon} alt="Up Arrow Icon" />
                        ) : (
                            <img src={UpArrowIcon} alt="Down Arrow Icon" style={{ transform: 'rotate(180deg)' }} />
                        )}
                    </a>
                </div>

                {/* Advanced Search Fields */}
                {advanceSearch && (
                    <div className='advanced-search-fields'>
                        <DynamicDropdown 
                            endpoint="https://deijobs.in/deijobs-api/api/work-modes"
                            iconSrc={icons["laptop-2.svg"]}
                            placeholder="Work Mode"
                            onSelect={(selected) => setWorkMode(selected)}
                        />
                        <DynamicDropdown 
                            endpoint="https://deijobs.in/deijobs-api/api/detartment"
                            iconSrc={icons['landmark.svg']}
                            placeholder='Department'
                            onSelect={(selected) => setPostedBy(selected)}
                        />
                        <DynamicDropdown 
                            endpoint="https://deijobs.in/deijobs-api/api/salary-ranges"
                            iconSrc={icons['git-commit.svg']}
                            placeholder='Salary Ranges'
                            onSelect={(selected) => setSalaryRange(selected)}
                        />
                        <DynamicDropdown 
                            endpoint="https://deijobs.in/deijobs-api/api/company-types"
                            iconSrc={icons['Group.svg']}
                            placeholder='Company Type'
                            onSelect={(selected) => setCompanyType(selected)}
                        />
                        <DynamicDropdown 
                            endpoint="https://deijobs.in/deijobs-api/api/job-roles"
                            iconSrc={icons['calendar.svg']}
                            placeholder='Role Category'
                            onSelect={(selected) => setFreshness(selected)}
                        />
                        <DynamicDropdown 
                            endpoint="https://deijobs.in/deijobs-api/api/get-education-list"
                            iconSrc={icons['layout-grid.svg']}
                            placeholder='Education'
                            onSelect={(selected) => setdeiCategory(selected)}
                        />
                        <DynamicDropdown 
                            endpoint="https://deijobs.in/deijobs-api/api/posted-by"
                            iconSrc={icons['briefcase.svg']}
                            placeholder='Posted By'
                            onSelect={(selected) => setPostedBy(selected)}
                        />
                        <DynamicDropdown 
                            endpoint="https://deijobs.in/deijobs-api/api/freshness"
                            iconSrc={icons['calendar-search.svg']}
                            placeholder='Freshness'
                            onSelect={(selected) => setFreshness(selected)}
                        />
                    </div>
                )}

                {/* Search Button */}
                <CustomButton onClick={handleSubmit} text='Search Jobs' />
            </div>
        </section>
    );
};

export default SearchJobs;
