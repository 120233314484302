import React ,{useState, useEffect} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getUserData ,createStudentProfile } from '../../services/profileServices';
import icons from '../../assets/icons'
import TabNav from "../../components/ui/TabNav";
import ProfileCard from '../../components/profile/ProfileCard';
import ProfileComplete from '../../components/ui/CompleteStatus';
import ToggleComponent from '../../components/ui/ToggleComponent ';
import MultiSelect from '../../components/form/MultiSelect';
import EducationForm from '../../components/profile/Education';
import WorkExperience from '../../components/profile/WorkExperience.js';
import ProfileFrom from '../../components/profile/ProfileForm';
import useUserData from '../../hooks/useUserData'; 
const ProfileAdd = () => {
  const location = useLocation();
  const userId = localStorage.getItem('login_token');
  const { profileData, isLoading } = useUserData();
  //const [profileData, setProfileData] = useState({});
  //const [isLoading, setIsLoading] = useState(true);
  const handleChange = (selectedOptions) => {
    console.log("Selected options:", selectedOptions);
    // Do something with the selected options
  };

  
  return (
    <>
        <section className='content'>
            <div className='container'>
                <div className='row'>
                  <div className='col-md-3'>
                    <ProfileCard/>
                  </div>
                  <div className='col-md-6'>
                   <TabNav/>
            <div className="card no-border shadow">
              <div className='card-head'>
                 Upload Photo Upload Resume
              </div>
                <div className='card-body'>
                    <ProfileFrom/>
                </div>
            </div>

            <div className="card no-border shadow">
              <div className='card-body'>
                <WorkExperience/>
              </div>  
            </div>
            <div className="card no-border shadow">
              <div className='card-body'>
                <EducationForm/>
              </div>  
            </div>

                                    
                  </div>
                  <div className="col-md-3">
              <div className="card verticle topcompanies p-4">
                <ProfileComplete/>
                
              </div>
            </div>
                
                </div>
            </div>
        </section>
    </>
  );
};
export default ProfileAdd;



