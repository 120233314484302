import React, { useState, useEffect } from 'react';
import { fetchData } from '../../services/apiServices'; // Adjust the path if necessary
import MultiSelect from '../form/MultiSelect';
import ToggleComponent from '../ui/ToggleComponent ';
import icons from '../../assets/icons'

const EducationForm = () => {
  const userId = localStorage.getItem('login_token');
  const [formData, setFormData] = useState({
    userId: userId,
    degree: '',
    university: '',
    yearOfPassing: '',
    percentage: '',
    description: '',
  });

  const [educationData, setEducationData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditing, setIsEditing] = useState(null); // For tracking which record is being edited

  // Handle text inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle MultiSelect inputs
  const handleMultiSelectChange = (selectedOption, fieldName) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption ? selectedOption.value : '',
    }));
  };

  // Handle deletion of an experience entry
  const handleDelete = async (experienceId) => {
    try {
      const response = await fetchData('delete-experience', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, experienceId }),
      });
      if (response.code === 200) {
        fetchEducationData(); // Fetch updated data after deletion
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  // Fetch profile data to display the old education data
  const fetchEducationData = async () => {
    try {
      const response = await fetchData('get-profile-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });
      if (response.code === 200) {
        setEducationData(response.data.education_data);
      } else {
        console.error('Error fetching education data:', response.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    // Fetch education data when the component is mounted
    fetchEducationData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetchData('add-student-education', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.code === 200) {
        setIsSubmitted(true);
        fetchEducationData(); // Fetch updated education data after successful submission
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Inline editing handlers
  const handleEdit = (index) => {
    setIsEditing(index); // Set the index of the record being edited
  };

  const handleSave = async (index) => {
    try {
      const updatedData = educationData[index];
      const response = await fetchData('update-student-education', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });
      if (response.code === 200) {
        setIsEditing(null); // Exit editing mode after saving
        fetchEducationData(); // Refresh the education data
      }
    } catch (error) {
      console.error('Error saving education data:', error);
    }
  };

  const handleInlineChange = (e, index) => {
    const { name, value } = e.target;
    const updatedEducationData = [...educationData];
    updatedEducationData[index] = {
      ...updatedEducationData[index],
      [name]: value,
    };
    setEducationData(updatedEducationData);
  };

  return (
    <>
     <ToggleComponent title="Education" icon={icons['briefcase.svg']}>
      {/* Form for adding new work experience */}
      <div className='card no-shadow'>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <label>Degree</label>
          <MultiSelect
            endpoint='get-degrees'
            onChange={(selectedOption) => handleMultiSelectChange(selectedOption, 'degree')}
          />
        </div>

        <div className="form-row">
          <label>University</label>
          <MultiSelect
            endpoint='get-universities'
            onChange={(selectedOption) => handleMultiSelectChange(selectedOption, 'university')}
          />
        </div>

        <div className="form-row">
          <label>Year of Passing</label>
          <input
            type="date"
            name="yearOfPassing"
            className="form-control"
            value={formData.yearOfPassing}
            onChange={handleChange}
          />
        </div>

        <div className="form-row">
          <label>Percentage</label>
          <input
            type="text"
            name="percentage"
            className="form-control"
            placeholder="Percentage"
            value={formData.percentage}
            onChange={handleChange}
          />
        </div>

        <div className="form-row">
          <label>Description</label>
          <textarea
            name="description"
            className="form-control"
            placeholder="Description"
            value={formData.description}
            onChange={handleChange}
          ></textarea>
        </div>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
      </div>
    </ToggleComponent>
      {educationData.length > 0 && (
        <div className="education-data-section">
          <ul>
            {educationData.map((education, index) => (
              <li key={index}>
                {isEditing === index ? (
                  <>
                    <strong>Degree:</strong>
                    <input
                      type="text"
                      name="degree"
                      value={education.degree}
                      onChange={(e) => handleInlineChange(e, index)}
                    />
                    <br />
                    <strong>University:</strong>
                    <input
                      type="text"
                      name="university"
                      value={education.university}
                      onChange={(e) => handleInlineChange(e, index)}
                    />
                    <br />
                    <strong>Passing Year:</strong>
                    <input
                      type="text"
                      name="passing_year"
                      value={education.passing_year}
                      onChange={(e) => handleInlineChange(e, index)}
                    />
                    <br />
                    <strong>Percentage:</strong>
                    <input
                      type="text"
                      name="percentage"
                      value={education.percentage}
                      onChange={(e) => handleInlineChange(e, index)}
                    />
                    <br />
                    <strong>Description:</strong>
                    <textarea
                      name="description"
                      value={education.description}
                      onChange={(e) => handleInlineChange(e, index)}
                    ></textarea>
                    <br />
                    <button onClick={() => handleSave(index)}>Save</button>
                  </>
                ) : (
                  <>
                  <div className='card'>
                  <div className=''>
                     <img src={icons['EducationCapRectangle.svg']} alt=''/>
                  </div>
                  <div className='d-flex_'>
                    <p><strong></strong>{education.university} </p>
                    <p style={{fontWeight:'300',color:'#4B5563'}}>{education.degree} </p>
                    <p style={{fontWeight:'300',color:'#4B5563'}}>{education.passing_year} </p>
                    {/* <p style={{fontWeight:'300',color:'#4B5563'}}>{education.percentage} </p>
                    <p style={{fontWeight:'300',color:'#4B5563'}}>{education.description}</p> */}
                  </div>
                    <div>
                      <button onClick={() => handleEdit(index)}>Edit</button>
                      <button onClick={() => handleDelete(education.id)}>Delete</button>
                    </div>
                  </div>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default EducationForm;
