import React from 'react';
import { Briefcase, User } from 'lucide-react';
import IconListItem from './IconListItem';
import useUserData from '../../hooks/useUserData';

const ProfileCard = () => {
  const { profileData, isLoading } = useUserData();

  // Display a loading message while fetching data
  if (isLoading) {
    return <p>Loading...</p>;
  }

  // If no user data, display an error or fallback message
  if (!profileData || !profileData.user_data || profileData.user_data.length === 0) {
    return <p>No user data found.</p>;
  }

  // Extract user details
  const user = profileData.user_data[0] || {};

  return (
    <>
      <div className='card profile_card'>
        <div className='profile_pic'>
          <img
            src={user.candidate_profile_picture || "https://deijobs.in/uploads/profile/user_default.png"}
            alt="User Profile"
          />
        </div>
        <div className='user_text'>
          <h2>{user.name || "Unknown User"}</h2>

          {/* Display Work Experience */}
          {profileData.work_experience && profileData.work_experience.length > 0 ? (
            <div>
              {profileData.work_experience.map((work, index) => (
                <div key={index}>
                  <h3 style={{ color: '#121224', fontWeight: '500', textTransform: 'capitalize' }} className='jobrole'>
                    {work.role_name || "Not Provided"}
                  </h3>
                  <h3 style={{ color: '#121224', textTransform: 'capitalize' }} className='companyname'>
                    {work.company_name || "Not Provided"}
                  </h3>
                </div>
              ))}
            </div>
          ) : (
            <p>No work experience available.</p>
          )}
        </div>

        {/* Profile Menu */}
        <div className='profile_menu'>
          <ul className='list_item'>
            <li>
              <IconListItem iconCode={<Briefcase />} iconText='Jobs' linkTo='/jobs' />
            </li>
            <li>
              <IconListItem iconCode={<User />} iconText='Profile' linkTo='/profile/add' />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ProfileCard;
