import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ProfileCard from '../../components/profile/ProfileCard';
import ProfileComplete from '../../components/ui/CompleteStatus';
import TabNav from '../../components/ui/TabNav';
import useUserData from '../../hooks/useUserData';

const ProfileOverview = () => {
  const location = useLocation();
  const userId = localStorage.getItem('login_token');
  const { profileData, isLoading } = useUserData();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  const user = profileData.user_data[0] || {};
  return (
    <section className='content'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-3'>
            <ProfileCard/>
          </div>
          <div className='col-md-6'>
            <TabNav/>
            <div className="card no-border shadow">
              <div className='card-head'>
                 Attached CV
              </div>
              <div className="card-body"  style={{background:'#F3F4F6'}}>
                <h5>Name: {profileData.user_data[0]?.name || 'N/A'}</h5>
                <h5>Email: {profileData.user_data[0]?.email || 'N/A'}</h5>
                <h5>DOB: {profileData.user_data[0]?.dob || 'N/A'}</h5>
                <h5>D & I Category: {profileData.user_data[0]?.dni_category || 'N/A'}</h5>
                <h5>Gender: {profileData.user_data[0]?.gender || 'N/A'}</h5>
                <h5>Job Role: {profileData.user_data[0]?.job_role || 'N/A'}</h5>
                <h5>Experience: {profileData.user_data[0]?.experience || 'N/A'} years</h5>

              {profileData.work_experience && profileData.work_experience.length > 0 ? (
                profileData.work_experience.map((work, index) => (
                  <div key={index}>
                    <h6>Company Name: {work.company_name || 'N/A'}</h6>
                    <h6>Role: {work.role_name || 'N/A'}</h6>
                    <h6>Start Date: {work.start_date || 'N/A'}</h6>
                    <h6>End Date: {work.end_date || 'N/A'}</h6>
                    <h6>Description: {work.description || 'N/A'}</h6>
                    <h6>Current Job: {work.is_current_company ? 'Yes' : 'No'}</h6>
                  </div>
                ))
              ) : (
                <p>No work experience found.</p>
              )}

           
              {profileData.education_data && profileData.education_data.length > 0 ? (
                profileData.education_data.map((edu, index) => (
                  <div key={index}>
                    <h6>Company Name: {edu.degree || 'N/A'}</h6>
                    <h6>Role: {edu.university || 'N/A'}</h6>
                    <h6>Passing Year: {edu.passing_year || 'N/A'}</h6>
                    <h6>Percentage: {edu.percentage || 'N/A'}</h6>
                    <h6>description: {edu.description || 'N/A'}</h6>
                  </div>
                ))
              ) : (
                <p>No work experience found.</p>
              )}
           
              {profileData.education_data && profileData.education_data.length > 0 ? (
                profileData.education_data.map((edu, index) => (
                  <div key={index}>
                    <h6>Company Name: {edu.degree || 'N/A'}</h6>
                    <h6>Role: {edu.university || 'N/A'}</h6>
                    <h6>Passing Year: {edu.passing_year || 'N/A'}</h6>
                    <h6>Percentage: {edu.percentage || 'N/A'}</h6>
                    <h6>description: {edu.description || 'N/A'}</h6>
                  </div>
                ))
              ) : (
                <p>No work experience found.</p>
              )}
          </div>
          </div>
            </div>
          <div className="col-md-3">
              <div className="card verticle topcompanies p-4">
              <ProfileComplete/>
              </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default ProfileOverview;
