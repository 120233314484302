// src/components/Sidebar.js
import React from "react";
import { Link } from "react-router-dom";
import { Menu, ChevronRight, UsersRound, User, MenuIcon ,Bell, User2} from "lucide-react";
import CardWithIcon from '../../components/ui/CardWithIcon';
import icons from '../../assets/icons';

const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
  const isLoggedIn = !!localStorage.getItem('login_token');
  const userId = localStorage.getItem("login_token");
  return (
    <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
      <div className="sidebar-content">
        {/* <button onClick={toggleSidebar} className="close-btn">X</button> */}
        {isLoggedIn ? (
            <>
        <CardWithIcon
            imageSrc={icons['resume.svg']}
            text="Featured Jobs"
            endIcon={<ChevronRight />}
            to="/showAll/featured-jobs"
        />
        <CardWithIcon
              imageSrc={icons['vector.svg']}
              startIcon={<UsersRound />}
              text="Login"
              endIcon={<ChevronRight />}
              to="/login"
            />
            <CardWithIcon
              imageSrc={icons['user.svg']}
              startIcon={<UsersRound />}
              text="Register"
              endIcon={<ChevronRight />}
              to="/login"
            />
            <CardWithIcon
              imageSrc={icons['employer.svg']}
              startIcon={<UsersRound />}
              text="Employer Login"
              endIcon={<ChevronRight />}
              to="/employer"
            />
            <CardWithIcon
            imageSrc={icons['users.svg']}
              startIcon={<UsersRound />}
              text="Community"
              endIcon={<ChevronRight />}
              to="/community"
            />
        </>
        ) :(
            <>
            <CardWithIcon
              imageSrc={icons['user']}
              startIcon={<UsersRound />}
              text="Logout"
              endIcon={<ChevronRight />}
              to="/login"
            />
            </>
        )}
      </div>
    </div>
  );
};
export default Sidebar;


