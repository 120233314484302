import React, { useState, useEffect } from 'react';
import DynamicDropdown from '../../components/form/DynamicDropdown';

const FilterForm = () =>{
    const userId = localStorage.getItem("login_token");   


    const [jobRole, setJobRole] = useState('');
    const [workMode, setWorkMode] = useState([]);
    const [salaryRange, setSalaryRange] = useState([]);
    const [postedBy, setPostedBy] = useState([]);
    const [companyType, setCompanyType] = useState([]);
    const [freshness, setFreshness] = useState([]);
    const [advanceSearch, setAdvanceSearch] = useState(false);

    const [dniCategory, setdniCategory] = useState('');
    const [gender, setGender] = useState('');
    const [experience, setExperience] = useState('');

    const handleSelect = (suggestion) => {
        console.log('Selected suggestion:', suggestion);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add logic to handle the form submission and search
        console.log({
          jobRole,
          //city,
          workMode,
          salaryRange,
          postedBy,
          companyType,
          freshness,
          //fullName,
          //dateOfBirth,
          dniCategory,
          gender,
          experience
        });
      };
    return(
        <>
            <div className='sidebarsearch'>            
              <div className='sidebar_body'>
              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/work-modes" 
                  //iconSrc={icons["laptop-2.svg"]}
                  placeholder="Work Mode"
              />
              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/detartment" 
                  //iconSrc={icons['landmark.svg']}
                  placeholder='Department'
              />
              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/salary-ranges" 
                  //={icons['git-commit.svg']}
                  placeholder='Salary Ranges'
              />
              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/company-types" 
                  //iconSrc={icons['Group.svg']}
                  placeholder='Company Type'
              />
              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/job-roles" 
                  //iconSrc={icons['calendar.svg']}
                  placeholder='Role Category'
              />
              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/education" 
                  //iconSrc={icons['layout-grid.svg']}
                  placeholder='Education'
              />
              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/posted-by" 
                  //iconSrc={icons['briefcase.svg']}
                  placeholder='Posted By'
              />
              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/top-companies-list" 
                 // iconSrc={icons['star.svg']}
                  placeholder='Top Companies'
              />


              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/freshness" 
                  //iconSrc={icons['calendar-search.svg']}
                  placeholder='Freshness'
              />
              </div>
              <div className='sidebar_foot'>
                 <div className=''>
                    <ul className='d-flex space-evenly'>
                        <li><a href='#' onClick={handleSubmit}>Close</a></li>
                        <li><a href='#' onClick={handleSubmit}>Apply</a></li>
                    </ul>
                 </div>
              </div>
            </div>
        </>
    )
};
export default FilterForm;